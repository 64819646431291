<template>
  <div>
    <div class="d-flex justify-end" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
      <div style="width: 80%"></div>
      <v-select
        class="pa-2"
        outlined
        filled
        dense
        v-model="dateRange"
        @input="dateRange => dateRangeChanged(dateRange)"
        :items="['This Month', 'Last Month', 'Today', 'Yesterday', 'This Year', 'Last Year', 'Custom Date']"
      ></v-select>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Custom Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker range v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')"> OK </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-card>
      <v-card-title>
        {{ translate('Clients') }}
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-simple-table fixed-header>
        <v-data-table :search="search" :headers="headers" :items="items" :items-per-page="20" class="elevation-1">
        </v-data-table></v-simple-table
    ></v-card>
    <div class="text-center">
      <downloadExcel
        class="elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile"
        :data="items"
        :fields="{ id: 'client_id', name: 'name', phone: 'phone', address: 'address', lat: 'lat', lng: 'lng' }"
      >
          Export Excel   
      </downloadExcel>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { mdiClipboardPlusOutline, mdiDotsVertical } from '@mdi/js'
import {
  changeUserRole,
  deleteUser,
  editSupplier,
  getClients,
  getSuppliers,
  getUsers,
  newUser,
  toggleUserActivation,
} from '@/ApiManager'

export default {
  setup() {
    const edit_supplier_name = ref('')
    const edit_supplier_email = ref('')
    const edit_supplier_phone = ref('')

    return {
      icons: {
        mdiClipboardPlusOutline,
        mdiDotsVertical,
      },
      edit_supplier_name,
      edit_supplier_email,
      edit_supplier_phone,
    }
  },
  data() {
    return {
      dateRange: 'This Month',
      date: null,
      menu: null,
      newUserDialog: false,
      search: '',
      dialog: false,
      delete_dialog: false,
      name: '',
      phone_number: '',
      email: '',
      password: '',
      userRole: '',
      errorText: '',

      headers: [
        { text: 'ID', value: 'id' },
        { text: this.translate('Name'), value: 'name' },
        { text: this.translate('Phone Number'), value: 'phone' },

        { text: this.translate('Address'), value: 'address' },
      ],
      items: [],
    }
  },
  created() {
    this.$store.state.loading = true
    getClients(this.dateRange).then(response => {
      this.items = response
      this.$store.state.loading = false
    })
  },
  methods: {
    dateRangeChanged(dateRange, customDate) {
      if (dateRange == 'Custom Date' && customDate == undefined) return
      this.$store.state.loading = true
      console.log(customDate)
      if (customDate != undefined) {
        this.dateRange = 'Custom Date'
      }
      getClients(dateRange).then(response => {
        this.items = response
        this.$store.state.loading = false
      })
    },
    async insertNewUser() {
      if (this.$refs['first_form'].validate() == false) {
        return
      }
      this.$store.state.loading = true
      var response = await newUser(this.name, this.phone_number, this.email, this.password, this.userRole)
      if (response == 'email_exists') {
        this.errorText = 'Email already exists.'
        this.$store.state.loading = false
        return
      }
      getUsers().then(response => {
        this.items = response
        this.$store.state.loading = false
      })
      this.$store.state.loading = false

      this.newUserDialog = false
    },
    async toggleActivation(item) {
      this.$store.state.loading = true

      await toggleUserActivation(item.id, item.activated == 1 ? 0 : 1)
      getUsers().then(response => {
        this.items = response
        this.$store.state.loading = false
      })
    },
    async userRoleChanged(item, role) {
      this.$store.state.loading = true

      await changeUserRole(item.id, role)
      this.$store.state.loading = false
    },
    editClicked(item) {
      this.edit_supplier_name = item.name
      this.edit_supplier_email = item.email
      this.edit_supplier_phone = item.phone_number
    },
    async handleDelete(id) {
      this.delete_dialog = false
      this.$store.state.loading = true
      await deleteUser(id)
      getUsers().then(response => {
        this.items = response
        this.$store.state.loading = false
      })
    },
    handleEdit(id) {
      this.$store.state.loading = true
      this.dialog = false
      editSupplier(id, this.edit_supplier_name, this.edit_supplier_email, this.edit_supplier_phone).then(() => {
        getSuppliers().then(response => {
          this.items = response
          this.$store.state.loading = false
        })
      })
      this.edit_supplier_name = ''
      this.edit_supplier_email = ''
      this.edit_supplier_phone = ''
    },
  },
}
</script>
